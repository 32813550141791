import {useCallback} from 'react'

import {MutatorOptions} from 'swr'
import useSWR from 'swr/immutable'

import {
  restApi,
  Variant,
  VariantList,
  VariantDetail,
  WishlistVariant,
  handleRestResponse,
} from '../api/rest'
import settings from '../constants/settings'
import {useAuth} from '../contexts'

import {getListingPrice} from '@festi/common/utils/price'
import {sendEcommerceAddToWishlistEvent} from '@festi/common/utils/tagmanager'

const wishlistCacheKey = 'all_wishlist_items'

export async function fetchWishlist() {
  return await handleRestResponse(
    restApi.wishlistItemsList({channel: settings.channel}),
  )
}

export function useWishlist() {
  const {user} = useAuth()

  const {
    data: wishlistItems,
    error,
    mutate,
    isValidating,
  } = useSWR(user ? wishlistCacheKey : null, fetchWishlist)

  const addVariantToWishlist = useCallback(
    (variant: Variant | VariantDetail | VariantList | WishlistVariant) => {
      mutate(
        async () => {
          await handleRestResponse(
            restApi.wishlistItemsCreate({
              sku: variant.sku || '',
              channel: settings.channel,
            }),
          )
          sendEcommerceAddToWishlistEvent(
            variant.name || '',
            variant.sku || '',
            getListingPrice(variant)?.price || 0,
            '',
            variant.recommended,
          )
          return await fetchWishlist()
        },
        {
          // optimisticData: [item, ...(wishlistItems ?? [])],
          rollbackOnError: true,
          revalidate: false,
        },
      )
    },
    [mutate],
  )

  const removeVariantFromWishlist = useCallback(
    async (id: number) => {
      const options: MutatorOptions = {
        optimisticData: wishlistItems?.filter((item) => item.id !== id),
        rollbackOnError: true,
        revalidate: false,
      }

      mutate(async () => {
        await restApi.wishlistItemsDestroy(id)
        return await fetchWishlist()
      }, options)
    },
    [wishlistItems, mutate],
  )

  const removeAllFromWishlist = useCallback(async () => {
    const options: MutatorOptions = {
      optimisticData: [],
      rollbackOnError: true,
      revalidate: false,
    }

    mutate(async () => {
      await Promise.all(
        wishlistItems?.map((item) => restApi.wishlistItemsDestroy(item.id)) ??
          [],
      )
      return await fetchWishlist()
    }, options)
  }, [wishlistItems, mutate])

  const isVariantInWishlist = useCallback(
    (sku: string) => {
      return wishlistItems?.some((i) => i.variant.sku === sku)
    },
    [wishlistItems],
  )

  const toggleVariantInWishlist = useCallback(
    (variant: Variant | VariantList | VariantDetail | WishlistVariant) => {
      isVariantInWishlist(variant?.sku || '')
        ? removeVariantFromWishlist(
            wishlistItems?.find((i) => i.variant.sku === variant?.sku)?.id ??
              variant.id,
          )
        : addVariantToWishlist(variant)
    },
    [
      wishlistItems,
      isVariantInWishlist,
      addVariantToWishlist,
      removeVariantFromWishlist,
    ],
  )

  return {
    error,
    isValidating,
    wishlistItems,
    isVariantInWishlist,
    addVariantToWishlist,
    removeAllFromWishlist,
    toggleVariantInWishlist,
    removeVariantFromWishlist,
  }
}
