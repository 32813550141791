import React, {useEffect, useState} from 'react'

import Link from 'next/link'
import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {CheckoutLine} from '@festi/common/api/rest'
import {useAuth, useCheckout} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import {formatPrice} from '@festi/utils/numbers'
import {fluidRange} from '@festi/common/utils/styles'
import {AuthSection} from '@festi/common/components/account/AuthDrawer'
import {DotsLoader, EmptyResults} from '@festi/common/components/common'
import {SideDrawer} from '@festi/common/components/layout'
import {CloseButton, MainButton} from '@festi/common/components/buttons'

import NewMiniCartItem from './NewMiniCartItem'
import {DrawerHeader} from 'src/components/search/redesign/components/SideDrawer'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
  onDutyFreeFrontPage?: boolean
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const CartWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 100px;
`

const CartHead = styled.div`
  font-weight: 500;
  color: ${palette('blue')};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
  gap: 8px;
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidRange('gap', '8px', '12px')};
  ${fluidRange('padding-top', '24px', '40px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
  ${fluidRange('padding-bottom', '24px', '40px')};
`

const TotalBox = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  ${fluidRange('padding-top', '20px', '24px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-bottom', '20px', '24px')};
  ${fluidRange('padding-left', '16px', '40px')};
  border-top: 1px solid ${palette('ui10Solid')};
  background-color: ${palette('backgroundGrey')};
  transition: opacity 0.15s;
  z-index: 1;
`

const TotalLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${palette('ui60Solid')};
  padding-right: 16px;
`

const TotalPrice = styled.span`
  font-family: 'ElkjopHeadline', sans-serif;
  color: ${palette('blue')};
  font-size: 32px;
`

export default function NewMiniCartDrawer({
  isOpen,
  onRequestClose,
  onDutyFreeFrontPage,
}: Props): JSX.Element {
  const {user} = useAuth()
  const [promptAuth, setPromptAuth] = useState<boolean>(false)

  const {restCheckout, restCheckoutLoading, cartIsUpdating, hasInsurableLines} =
    useCheckout()

  const nextStep = hasInsurableLines ? '/karfa/trygging/' : '/kaup/'

  const handleClick = (e: React.MouseEvent) => {
    if (!user) {
      e.preventDefault()
      setPromptAuth(true)
    }
  }

  useEffect(() => {
    if (user && promptAuth) {
      setPromptAuth(false)
    }
  }, [promptAuth, user])

  return (
    <SideDrawer
      isOpen={isOpen}
      noFocus={restCheckoutLoading || !restCheckout?.quantity}
      onRequestClose={onRequestClose}
      onDutyFreeFrontPage={onDutyFreeFrontPage}
      newDesign
    >
      {promptAuth ? (
        <AuthSection inCheckout />
      ) : (
        <Content>
          {restCheckoutLoading ? (
            <DotsLoader />
          ) : (
            <CartWrapper>
              <DrawerHeader>
                <CartHead>Yfirlit körfu</CartHead>

                <CloseButton onClick={onRequestClose} />
              </DrawerHeader>

              {!restCheckout?.quantity ? (
                <EmptyResults icon={ShoppingCartLineIcon}>
                  <span data-cy="minicart-empty">Karfan þín er tóm</span>
                </EmptyResults>
              ) : (
                <>
                  <ButtonsWrapper>
                    <Link href="/karfa" passHref legacyBehavior>
                      <MainButton
                        onClick={onRequestClose}
                        label="Skoða körfu"
                        prefixIcon={ShoppingCartLineIcon}
                        buttonVariant="ghost"
                        fullWidth
                      />
                    </Link>

                    <Link href={nextStep} passHref legacyBehavior>
                      <MainButton
                        onClick={handleClick}
                        label={onDutyFree ? 'Sækja við brottför' : 'Klára kaup'}
                        suffixIcon={ArrowRightSLineIcon}
                        fullWidth
                      />
                    </Link>
                  </ButtonsWrapper>

                  <Items>
                    {restCheckout?.lines?.map((line: CheckoutLine) => (
                      <NewMiniCartItem
                        key={line.id}
                        line={line}
                        onClick={onRequestClose}
                      />
                    ))}
                  </Items>

                  <TotalBox style={{opacity: cartIsUpdating ? 0.4 : 1}}>
                    <TotalLabel>samtals</TotalLabel>

                    <TotalPrice>
                      {formatPrice(restCheckout?.totalPrice)}
                    </TotalPrice>
                  </TotalBox>
                </>
              )}
            </CartWrapper>
          )}
        </Content>
      )}
    </SideDrawer>
  )
}
