import styled from 'styled-components'

import {styledTheme} from '@festi/common/themes'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: ${styledTheme.palette.ui10Solid};
`

interface ButtonProps {
  toggle: boolean
}

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  border: none;
  font-weight: 500;
  border-radius: 8px;
  color: ${({toggle}) =>
    toggle ? styledTheme.palette.blue : styledTheme.palette.ui60Solid};
  background-color: ${({toggle}) =>
    toggle
      ? styledTheme.palette.backgroundGrey
      : styledTheme.palette.ui10Solid};
  cursor: pointer;
`

interface Props {
  selected: string
  toggleItems: string[]
  handleToggle: (value: string) => void
}

export default function Toggle({selected, toggleItems, handleToggle}: Props) {
  return (
    <Wrapper>
      {toggleItems?.map((item) => (
        <Button
          key={item}
          toggle={item === selected}
          onClick={() => handleToggle(item)}
        >
          {item}
        </Button>
      ))}
    </Wrapper>
  )
}
